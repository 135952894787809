<template>
  <v-container class="pa-0">
    <v-form
      v-model="
        contractAmendmentStore.contractAmendmentFormValidation.contractData
      "
    >
      <v-row
        v-if="
          !contractAmendmentStore.contractAmendmentData.isAddressCompleted ||
          !contractAmendmentStore.contractAmendmentData.isEmailFilled
        "
      >
        <v-col cols="12" class="px-12">
          <WarningAlert
            v-if="
              !contractAmendmentStore.contractAmendmentData.isAddressCompleted
            "
            :text="t('Action_.alert.addressWarning')"
          />
          <WarningAlert
            v-if="!contractAmendmentStore.contractAmendmentData.isEmailFilled"
            class="mt-1"
            :text="t('Action_.alert.emailWarning')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <DropdownInput
            :clearable="false"
            :items="profileStore.profileHubs"
            item-value="id"
            item-text="name"
            :label="t('Msg_.mainHub') + '*'"
            v-model="contractAmendmentStore.contractAmendmentData.mainHub"
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.mainHub')} ${t('Msg_.isRequired')}`
              ),
            ]"
            data-testid="profile-contract-amendment-main-hub-dropdown"
          />
        </v-col>
        <v-col cols="4">
          <RegularTextInput
            :label="t('Msg_.hub')"
            :value="billingHub"
            disabled
            data-testid="profile-contract-amendment-billing-hub-field"
          />
        </v-col>
        <v-col cols="4">
          <RegularTextInput
            :label="t('Msg_.company')"
            :value="companyName"
            disabled
            data-testid="profile-contract-amendment-company-field"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" class="pt-0">
          <DropdownInput
            :label="t('Msg_.contractType') + '*'"
            :items="selectContractTypes"
            v-model="contractAmendmentStore.contractAmendmentData.contractType"
            data-testid="profile-contract-amendment-contract-type-dropdown"
            :clearable="false"
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.contractType')} ${t('Msg_.isRequired')}`
              ),
            ]"
            @change="handleCompensationType()"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <DropdownInput
            :label="t('Msg_.compensationType')"
            :items="selectCompensationTypes"
            v-model="
              contractAmendmentStore.contractAmendmentData.compensationType
            "
            disabled
            data-testid="profile-contract-amendment-compensation-type-dropdown"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" class="pt-0">
          <DropdownInput
            :clearable="false"
            :label="t('Msg_.jobTitle') + '*'"
            :items="selectQualificationType"
            :value="contractAmendmentStore.contractAmendmentData.jobTitle"
            @change="handleJobTitle($event)"
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.jobTitle')} ${t('Msg_.isRequired')}`
              ),
            ]"
            data-testid="profile-contract-amendment-job-title-dropdown"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <RegularTextInput
            data-testid="profile-contract-amendment-job-subtitle-field"
            :label="t('Msg_.jobSubtitle')"
            v-model="contractAmendmentStore.contractAmendmentData.jobSubtitle"
            :rules="[
              rules.valueSize(
                50,
                `${t('Msg_.jobSubtitle')} ${t(
                  'Msg_.rules.fieldMustHaveLessThan'
                )} 50 ${t('Msg_.rules.characters')}`
              ),
            ]"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <Combobox
            :label="t('Msg_.additionalTraining') + '*'"
            :items="selectAdditionalTrainingType"
            :value="
              contractAmendmentStore.contractAmendmentData.additionalTraining
            "
            :clearable="false"
            @click="handleAdditionaTrainingField($event)"
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.additionalTraining')} ${t('Msg_.isRequired')}`
              ),
              (v) =>
                v?.length > 0 ||
                `${t('Msg_.additionalTraining')} ${t('Msg_.isRequired')}`,
            ]"
            data-testid="contract-creation-additional-training-field"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script setup>
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import Combobox from '@/components/shared/customComponents/inputs/combobox-input.vue';
import WarningAlert from '@/components/shared/customComponents/alertsBadges/warning-alert.vue';
import languages from '../locales/languages';
import helpers from '@/helpers/helpers';
import rules from '@/helpers/rules';
import { useI18n } from 'vue-i18n-bridge';
import { useProfileStore } from '@/store/pinia/profile.store.js';
import { useContractAmendmentStore } from '@/store/pinia/contract-amendment.store.js';
import { computed } from 'vue';
import { useContractTools } from '@/helpers/composables/contract-tools.js';
import { useContractToolsV2 } from '@/helpers/composables/contract-tools-v2.js';

const profileStore = useProfileStore();
const contractAmendmentStore = useContractAmendmentStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });
const { validateAdditionalTraining } = useContractTools();
const { updateCompensationType } = useContractToolsV2();

// --- Methods ---
const handleJobTitle = (input) => {
  contractAmendmentStore.contractAmendmentData.jobTitle = input;
};

const handleAdditionaTrainingField = (payload) => {
  contractAmendmentStore.contractAmendmentData.additionalTraining =
    validateAdditionalTraining(payload);
};

const handleCompensationType = () => {
  contractAmendmentStore.contractAmendmentData.compensationType =
    updateCompensationType(contractAmendmentStore.contractAmendmentData);
};

// --- Computed ---
const billingHub = computed(() => {
  const source = profileStore.profileHubs.filter((hub) => {
    return hub.id === contractAmendmentStore.contractAmendmentData.mainHub;
  });
  return source[0]?.billingHubName || '';
});

const companyName = computed(() => {
  const source = profileStore.profileHubs.filter((hub) => {
    return hub.id === contractAmendmentStore.contractAmendmentData.mainHub;
  });
  return source[0]?.companyName || '';
});

const selectAdditionalTrainingType = computed(() => {
  let actionSelectLevelOfEducationType = tm(
    'Action_.selectAdditionalTrainingTypes'
  );

  return helpers.translateAndAdaptToVuetify(
    actionSelectLevelOfEducationType,
    profileStore.profileResources?.additionalTrainingTypes
  );
});

const selectCompensationTypes = computed(() => {
  let actionSelectCompensationTypes = tm('Action_.selectCompensationType');

  return helpers.translateAndAdaptToVuetify(
    actionSelectCompensationTypes,
    profileStore.profileResources?.contractCreationCompensationTypes
  );
});

const selectContractTypes = computed(() => {
  let actionSelectContractTypes = tm('Action_.selectContractType');

  return helpers.translateAndAdaptToVuetify(
    actionSelectContractTypes,
    profileStore.profileResources?.contractTypes
  );
});

const selectQualificationType = computed(() => {
  let actionSelectQualificationType = tm('Action_.selectQualificationType');

  return helpers.translateAndAdaptToVuetify(
    actionSelectQualificationType,
    profileStore.profileResources?.contractCreationQualificationTypes
  );
});
</script>

<style scoped lang="scss"></style>

<template>
  <v-container class="pa-0">
    <v-form
      ref="form"
      v-model="
        contractAmendmentStore.contractAmendmentFormValidation.fullTimePartTime
      "
    >
      <v-row>
        <v-col cols="3">
          <RegularTextInput
            disabled
            :label="t('Msg_.weeklyWorkingDays')"
            v-model="
              contractAmendmentStore.contractAmendmentData
                .totalWeeklyWorkingDays
            "
            :rules="[
              rules.smallerOrEqualThan(6, t('Msg_.rules.maximumValueExceeded')),
            ]"
            data-testid="profile-contract-amendment-weekly-working-days-field"
          />
        </v-col>
        <v-col cols="9">
          <v-row>
            <v-col
              v-for="(item, index) in contractAmendmentStore
                .contractAmendmentData.weeklyWorkingHours"
              :key="index"
              class="flex-grow-1"
              cols="auto"
            >
              <SwitchControl
                :label="tm(`Msg_.weekDays`)[index]"
                :value="item.isActive"
                @click="updateDailyWorkingHours(index)"
                :data-testid="`profile-contract-amendment-week-day-${index}-field`"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="pt-0">
          <RegularTextInput
            :label="t('Msg_.weeklyWorkingHours') + '*'"
            v-model="
              contractAmendmentStore.contractAmendmentData
                .totalWeeklyWorkingHours
            "
            @change="handleTotalWeeklyWorkingHours()"
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.weeklyWorkingHours')} ${t('Msg_.isRequired')}`
              ),
              rules.numberWithDecimals(t('Msg_.rules.onlyNumbers')),
              rules.smallerOrEqualThan(
                contractAmendmentStore.contractAmendmentData
                  .totalWeeklyWorkingDays * 24,
                t('Msg_.rules.maximumValueExceeded')
              ),
            ]"
            data-testid="profile-contract-amendment-weekly-working-hours-field"
          />
        </v-col>
        <v-col
          v-for="(item, index) in contractAmendmentStore.contractAmendmentData
            .weeklyWorkingHours"
          :key="index"
          class="pt-0"
        >
          <RegularTextInput
            disabled
            :label="tm(`Msg_.weekDays`)[index]"
            v-model="item.hours"
            :data-testid="`profile-contract-amendment-week-day-${
              tm(`Msg_.weekDays`)[index]
            }-field`"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pt-0">
          <RegularTextInput
            disabled
            :label="t('Msg_.vacationDays') + '*'"
            :value="vacationDaysPerYear"
            data-testid="profile-contract-amendment-vacation-days-field"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" class="pt-0">
          <DropdownInput
            :clearable="false"
            :items="selectJobLevelTypes"
            :label="t('Msg_.jobLevel') + '*'"
            v-model="contractAmendmentStore.contractAmendmentData.jobLevel"
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.jobLevel')} ${t('Msg_.isRequired')}`,
                0
              ),
            ]"
            data-testid="profile-contract-amendment-job-level-dropdown"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <DropdownInput
            :clearable="false"
            :items="selectExperienceLevelTypes"
            :label="t('Msg_.experienceLevel') + '*'"
            v-model="
              contractAmendmentStore.contractAmendmentData.experienceLevel
            "
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.experienceLevel')} ${t('Msg_.isRequired')}`,
                0
              ),
            ]"
            data-testid="profile-contract-amendment-experience-level-dropdown"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <RegularTextInput
            maxlength="12"
            icon="mdi-currency-eur"
            :label="t('Msg_.cityBonus') + '*'"
            :value="
              helpers.convertValueToCurrency(
                contractAmendmentStore.contractAmendmentData.cityBonus
              )
            "
            @change="
              contractAmendmentStore.contractAmendmentData.cityBonus =
                helpers.convertValueToCurrency($event)
            "
            @focus="
              contractAmendmentStore.contractAmendmentData.cityBonus =
                helpers.convertCurrencyToFloat($event)
            "
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.cityBonus')} ${t('Msg_.isRequired')}`,
                0
              ),
              rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
            ]"
            data-testid="profile-contract-amendment-city-bonus-field"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" class="pt-0">
          <RegularTextInput
            maxlength="12"
            icon="mdi-currency-eur"
            :label="`${t('Msg_.hourlyWage')}*`"
            :value="
              helpers.convertValueToCurrency(
                contractAmendmentStore.contractAmendmentData.hourlyWage
              )
            "
            @change="
              contractAmendmentStore.contractAmendmentData.hourlyWage =
                helpers.convertValueToCurrency($event)
            "
            @focus="
              contractAmendmentStore.contractAmendmentData.hourlyWage =
                helpers.convertCurrencyToFloat($event)
            "
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.hourlyWage')} ${t('Msg_.isRequired')}`,
                0
              ),
              rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
            ]"
            data-testid="profile-contract-amendment-hourly-wage-field"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <RegularTextInput
            maxlength="12"
            icon="mdi-currency-eur"
            :label="`${t('Msg_.nonDynamicAllowance')}*`"
            :value="
              helpers.convertValueToCurrency(
                contractAmendmentStore.contractAmendmentData.nonDynamicAllowance
              )
            "
            @change="
              contractAmendmentStore.contractAmendmentData.nonDynamicAllowance =
                helpers.convertValueToCurrency($event)
            "
            @focus="
              contractAmendmentStore.contractAmendmentData.nonDynamicAllowance =
                helpers.convertCurrencyToFloat($event)
            "
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.nonDynamicAllowance')} ${t('Msg_.isRequired')}`,
                0
              ),
              rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
            ]"
            data-testid="profile-contract-amendment-non-dynamic-allowance-field"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <RegularTextInput
            data-testid="profile-contract-amendment-care-allowance-field"
            maxlength="12"
            icon="mdi-currency-eur"
            :label="`${t('Msg_.careAllowance')}*`"
            :value="
              helpers.convertValueToCurrency(
                contractAmendmentStore.contractAmendmentData.careAllowance
              )
            "
            @change="
              contractAmendmentStore.contractAmendmentData.careAllowance =
                helpers.convertValueToCurrency($event)
            "
            @focus="
              contractAmendmentStore.contractAmendmentData.careAllowance =
                helpers.convertCurrencyToFloat($event)
            "
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.careAllowance')} ${t('Msg_.isRequired')}`,
                0
              ),
              rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
            ]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pt-0">
          <RegularTextInput
            maxlength="12"
            icon="mdi-currency-eur"
            :label="`${t('Msg_.individualAllowance')}*`"
            :value="
              helpers.convertValueToCurrency(
                contractAmendmentStore.contractAmendmentData.individualAllowance
              )
            "
            @change="
              contractAmendmentStore.contractAmendmentData.individualAllowance =
                helpers.convertValueToCurrency($event)
            "
            @focus="
              contractAmendmentStore.contractAmendmentData.individualAllowance =
                helpers.convertCurrencyToFloat($event)
            "
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.individualAllowance')} ${t('Msg_.isRequired')}`,
                0
              ),
              rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
            ]"
            data-testid="profile-contract-amendment-individual-allowance-field"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <RegularTextInput
            :value="
              helpers.convertValueToCurrency(
                contractAmendmentStore.contractAmendmentData.monthlyBaseSalary
              )
            "
            maxlength="12"
            icon="mdi-currency-eur"
            :label="`${t('Msg_.monthlyBaseSalary')}*`"
            @change="
              contractAmendmentStore.contractAmendmentData.monthlyBaseSalary =
                helpers.convertValueToCurrency($event)
            "
            @focus="
              contractAmendmentStore.contractAmendmentData.monthlyBaseSalary =
                helpers.convertCurrencyToFloat($event)
            "
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.monthlyBaseSalary')} ${t('Msg_.isRequired')}`,
                0
              ),
              rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
            ]"
            data-testid="profile-contract-amendment-monthly-base-salary-field"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <RegularTextInput
            data-testid="profile-contract-amendment-thirteenth-salary-field"
            maxlength="12"
            icon="mdi-currency-eur"
            :label="`${t('Msg_.13thSalary')}*`"
            :value="
              helpers.convertValueToCurrency(
                contractAmendmentStore.contractAmendmentData.thirteenthSalary
              )
            "
            @change="
              contractAmendmentStore.contractAmendmentData.thirteenthSalary =
                helpers.convertValueToCurrency($event)
            "
            @focus="
              contractAmendmentStore.contractAmendmentData.thirteenthSalary =
                helpers.convertCurrencyToFloat($event)
            "
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.13thSalary')} ${t('Msg_.isRequired')}`,
                0
              ),
              rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
            ]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pt-0">
          <RegularTextInput
            maxlength="12"
            icon="mdi-currency-eur"
            :label="`${t('Msg_.annualSalary')}*`"
            :value="
              helpers.convertValueToCurrency(
                contractAmendmentStore.contractAmendmentData.annualSalary
              )
            "
            @change="
              contractAmendmentStore.contractAmendmentData.annualSalary =
                helpers.convertValueToCurrency($event)
            "
            @focus="
              contractAmendmentStore.contractAmendmentData.annualSalary =
                helpers.convertCurrencyToFloat($event)
            "
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.annualSalary')} ${t('Msg_.isRequired')}`,
                0
              ),
              rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
            ]"
            data-testid="profile-contract-amendment-annual-salary-field"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <RegularTextInput
            maxlength="12"
            icon="mdi-currency-eur"
            :label="`${t('Msg_.bonusTarget')}*`"
            :value="
              helpers.convertValueToCurrency(
                contractAmendmentStore.contractAmendmentData.bonusTarget
              )
            "
            @change="
              contractAmendmentStore.contractAmendmentData.bonusTarget =
                helpers.convertValueToCurrency($event)
            "
            @focus="
              contractAmendmentStore.contractAmendmentData.bonusTarget =
                helpers.convertCurrencyToFloat($event)
            "
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.annualSalary')} ${t('Msg_.isRequired')}`,
                0
              ),
              rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
            ]"
            data-testid="profile-contract-amendment-bonus-target-field"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" class="pt-0">
          <DatePicker
            v-model="contractAmendmentStore.contractAmendmentData.startDate"
            :label="t('Msg_.startDateChangeEffective') + '*'"
            :allowed-dates="allowedStartDate"
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.startDateChangeEffective')} ${t('Msg_.isRequired')}`
              ),
            ]"
            data-testid="profile-contract-amendment-start-date-field"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <RadioControl
            class="mt-1"
            :label="t('Msg_.duration') + '*'"
            :value="contractAmendmentStore.contractAmendmentData.duration"
            :label-true="t('Msg_.unlimited')"
            :label-false="t('Msg_.limited')"
            :return-value-true="1"
            :return-value-false="2"
            @change="handleDurationField($event)"
            data-testid="profile-contract-amendment-duration-field"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <DatePicker
            v-model="contractAmendmentStore.contractAmendmentData.endDate"
            :label="endDateLabel"
            :allowed-dates="allowedEndDate"
            :disabled="isContractUnlimited"
            :rules="endDateRules"
            data-testid="profile-contract-amendment-end-date-field"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" class="pt-0">
          <DatePicker
            v-model="contractAmendmentStore.contractAmendmentData.signingDate"
            :label="t('Msg_.signingDate') + '*'"
            :rules="[
              rules.fieldRequired(
                `${t('Msg_.signingDate')} ${t('Msg_.isRequired')}`
              ),
            ]"
            data-testid="profile-contract-amendment-signing-date-field"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <RegularTextInput
            disabled
            :label="t('Msg_.assigner')"
            :value="userStore.userInfo.name"
            data-testid="profile-contract-amendment-assigner-field"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script setup>
import languages from '../locales/languages';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import SwitchControl from '@/components/shared/customComponents/controls/switch-control.vue';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import RadioControl from '@/components/shared/customComponents/controls/radio-control.vue';
import helpers from '@/helpers/helpers';
import rules from '@/helpers/rules';
import { useUserStore } from '@/store/pinia/user.store.js';
import { useProfileStore } from '@/store/pinia/profile.store.js';
import { useContractAmendmentStore } from '@/store/pinia/contract-amendment.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed, onBeforeMount, nextTick } from 'vue';
import { useContractToolsV2 } from '@/helpers/composables/contract-tools-v2.js';

const form = ref(null);
const userStore = useUserStore();
const profileStore = useProfileStore();
const contractAmendmentStore = useContractAmendmentStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });
const { updateCompensationType } = useContractToolsV2();

// --- Methods ---
const updateDailyWorkingHours = (index) => {
  updateWeekDay(index);
  contractAmendmentStore.contractAmendmentData.totalWeeklyWorkingDays =
    totalActiveSwitches();
  contractAmendmentStore.contractAmendmentData.vacationDays =
    vacationDaysPerYear.value;
  updateSplitHours();
};

const updateWeekDay = (index) => {
  let freeDay = 0;
  contractAmendmentStore.contractAmendmentData.weeklyWorkingHours[
    index
  ].isActive =
    !contractAmendmentStore.contractAmendmentData.weeklyWorkingHours[index]
      .isActive;

  if (
    !contractAmendmentStore.contractAmendmentData.weeklyWorkingHours[index]
      .isActive
  ) {
    contractAmendmentStore.contractAmendmentData.weeklyWorkingHours[
      index
    ].hours = freeDay.toFixed(2);
  }
};

const updateSplitHours = async () => {
  let splitTime =
    contractAmendmentStore.contractAmendmentData.totalWeeklyWorkingHours /
    totalActiveSwitches();

  contractAmendmentStore.contractAmendmentData.weeklyWorkingHours.forEach(
    (day, index) => {
      if (day.isActive) {
        contractAmendmentStore.contractAmendmentData.weeklyWorkingHours[
          index
        ].hours = splitTime.toFixed(2);
      }
    }
  );
  await nextTick();
  form.value.validate();
};

const totalActiveSwitches = () => {
  return contractAmendmentStore.contractAmendmentData.weeklyWorkingHours.filter(
    (day) => day.isActive === true
  ).length;
};

const handleDurationField = (value) => {
  contractAmendmentStore.contractAmendmentData.duration = value;
  if (value === 1 && contractAmendmentStore.contractAmendmentData.endDate)
    contractAmendmentStore.contractAmendmentData.endDate = '';
  form.value.validate();
};

const handleTotalWeeklyWorkingHours = () => {
  updateSplitHours();
  handleCompensationType();
};

const handleCompensationType = () => {
  contractAmendmentStore.contractAmendmentData.compensationType =
    updateCompensationType(contractAmendmentStore.contractAmendmentData);
};

const allowedStartDate = (val) => {
  return parseInt(val.split('-')[2]) === 1;
};

const allowedEndDate = (val) => {
  return val > contractAmendmentStore.contractAmendmentData.startDate;
};

const initTotalWorkingDays = () => {
  const validateWorkingDays =
    !contractAmendmentStore.contractAmendmentData.totalWeeklyWorkingDays ||
    contractAmendmentStore.contractAmendmentData.totalWeeklyWorkingDays == 0;

  if (validateWorkingDays) {
    contractAmendmentStore.contractAmendmentData.totalWeeklyWorkingDays =
      totalActiveSwitches();
  }
};

// --- Computed ---
const isContractUnlimited = computed(() => {
  const unlimitedType = profileStore.profileResources.contractPeriodTypes?.find(
    (type) => type.Name === 'Unlimited'
  );

  return (
    contractAmendmentStore.contractAmendmentData.duration ===
    unlimitedType?.Value
  );
});

const endDateRules = computed(() => {
  const message = `${t('Msg_.endDateChangeEffective')} ${t('Msg_.isRequired')}`;
  return isContractUnlimited.value ? [] : [rules.fieldRequired(message)];
});

const endDateLabel = computed(() => {
  const message = t('Msg_.endDateChangeEffective');
  return isContractUnlimited.value ? message : message + '*';
});

const selectExperienceLevelTypes = computed(() => {
  let actionSelectExperienceLevel = tm('Action_.selectExperienceLevelTypes');

  return helpers.translateAndAdaptToVuetify(
    actionSelectExperienceLevel,
    profileStore.profileResources.experienceLevelTypes
  );
});

const selectJobLevelTypes = computed(() => {
  let actionSelectJobLevel = tm('Action_.selectJobLevelTypes');

  return helpers.translateAndAdaptToVuetify(
    actionSelectJobLevel,
    profileStore.profileResources.jobLevelTypes
  );
});

const vacationDaysPerYear = computed(() => {
  let vacationsPerYear = null;

  if (
    profileStore.profileResources?.vacationDaysCalculations &&
    contractAmendmentStore.contractAmendmentData.totalWeeklyWorkingDays !== 7
  ) {
    const selectedVacationDaysCalculations =
      profileStore.profileResources?.vacationDaysCalculations.find(
        (days) =>
          days.weeklyWorkingDays ===
          contractAmendmentStore.contractAmendmentData.totalWeeklyWorkingDays
      );
    vacationsPerYear = selectedVacationDaysCalculations.yearlyVacationDays;
  }

  return vacationsPerYear;
});

// Lifecycle hooks
onBeforeMount(() => {
  initTotalWorkingDays();
});
</script>

<style scoped lang="scss"></style>

<template>
  <div data-testid="radio-control">
    <p class="custom-label">{{ label }}</p>
    <v-radio-group
      class="pt-0"
      row
      dense
      :value="value"
      :disabled="disabled"
      hide-details
      @change="emitUserInput($event)"
    >
      <v-radio color="blueRegular" :value="returnValueTrue">
        <template #label>
          <span class="checkbox-label">{{ labelTrue }}</span>
        </template>
      </v-radio>
      <v-radio color="blueRegular" :value="returnValueFalse">
        <template #label>
          <span class="checkbox-label">{{ labelFalse }}</span>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['input', 'change']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  returnValueTrue: {
    type: null,
    required: false,
    default: true,
  },
  returnValueFalse: {
    type: null,
    required: false,
    default: false,
  },
  labelTrue: {
    type: String,
    required: true,
  },
  labelFalse: {
    type: String,
    required: true,
  },
  value: {
    type: null,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const emitUserInput = (input) => {
  emit('input', input);
  emit('change', input);
};
</script>

<style lang="scss" scoped>
.custom-label {
  font-size: 13px;
}
</style>

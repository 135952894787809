var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0"},[_c('v-form',{ref:"form",model:{value:(
      _setup.contractAmendmentStore.contractAmendmentFormValidation.fullTimePartTime
    ),callback:function ($$v) {_vm.$set(_setup.contractAmendmentStore.contractAmendmentFormValidation, "fullTimePartTime", $$v)},expression:"\n      contractAmendmentStore.contractAmendmentFormValidation.fullTimePartTime\n    "}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c(_setup.RegularTextInput,{attrs:{"disabled":"","label":_setup.t('Msg_.weeklyWorkingDays'),"rules":[
            _setup.rules.smallerOrEqualThan(6, _setup.t('Msg_.rules.maximumValueExceeded')),
          ],"data-testid":"profile-contract-amendment-weekly-working-days-field"},model:{value:(
            _setup.contractAmendmentStore.contractAmendmentData
              .totalWeeklyWorkingDays
          ),callback:function ($$v) {_vm.$set(_setup.contractAmendmentStore.contractAmendmentData
              , "totalWeeklyWorkingDays", $$v)},expression:"\n            contractAmendmentStore.contractAmendmentData\n              .totalWeeklyWorkingDays\n          "}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-row',_vm._l((_setup.contractAmendmentStore
              .contractAmendmentData.weeklyWorkingHours),function(item,index){return _c('v-col',{key:index,staticClass:"flex-grow-1",attrs:{"cols":"auto"}},[_c(_setup.SwitchControl,{attrs:{"label":_setup.tm(`Msg_.weekDays`)[index],"value":item.isActive,"data-testid":`profile-contract-amendment-week-day-${index}-field`},on:{"click":function($event){return _setup.updateDailyWorkingHours(index)}}})],1)}),1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"3"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.weeklyWorkingHours') + '*',"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.weeklyWorkingHours')} ${_setup.t('Msg_.isRequired')}`
            ),
            _setup.rules.numberWithDecimals(_setup.t('Msg_.rules.onlyNumbers')),
            _setup.rules.smallerOrEqualThan(
              _setup.contractAmendmentStore.contractAmendmentData
                .totalWeeklyWorkingDays * 24,
              _setup.t('Msg_.rules.maximumValueExceeded')
            ),
          ],"data-testid":"profile-contract-amendment-weekly-working-hours-field"},on:{"change":function($event){return _setup.handleTotalWeeklyWorkingHours()}},model:{value:(
            _setup.contractAmendmentStore.contractAmendmentData
              .totalWeeklyWorkingHours
          ),callback:function ($$v) {_vm.$set(_setup.contractAmendmentStore.contractAmendmentData
              , "totalWeeklyWorkingHours", $$v)},expression:"\n            contractAmendmentStore.contractAmendmentData\n              .totalWeeklyWorkingHours\n          "}})],1),_vm._l((_setup.contractAmendmentStore.contractAmendmentData
          .weeklyWorkingHours),function(item,index){return _c('v-col',{key:index,staticClass:"pt-0"},[_c(_setup.RegularTextInput,{attrs:{"disabled":"","label":_setup.tm(`Msg_.weekDays`)[index],"data-testid":`profile-contract-amendment-week-day-${
            _setup.tm(`Msg_.weekDays`)[index]
          }-field`},model:{value:(item.hours),callback:function ($$v) {_vm.$set(item, "hours", $$v)},expression:"item.hours"}})],1)})],2),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"disabled":"","label":_setup.t('Msg_.vacationDays') + '*',"value":_setup.vacationDaysPerYear,"data-testid":"profile-contract-amendment-vacation-days-field"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"clearable":false,"items":_setup.selectJobLevelTypes,"label":_setup.t('Msg_.jobLevel') + '*',"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.jobLevel')} ${_setup.t('Msg_.isRequired')}`,
              0
            ),
          ],"data-testid":"profile-contract-amendment-job-level-dropdown"},model:{value:(_setup.contractAmendmentStore.contractAmendmentData.jobLevel),callback:function ($$v) {_vm.$set(_setup.contractAmendmentStore.contractAmendmentData, "jobLevel", $$v)},expression:"contractAmendmentStore.contractAmendmentData.jobLevel"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"clearable":false,"items":_setup.selectExperienceLevelTypes,"label":_setup.t('Msg_.experienceLevel') + '*',"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.experienceLevel')} ${_setup.t('Msg_.isRequired')}`,
              0
            ),
          ],"data-testid":"profile-contract-amendment-experience-level-dropdown"},model:{value:(
            _setup.contractAmendmentStore.contractAmendmentData.experienceLevel
          ),callback:function ($$v) {_vm.$set(_setup.contractAmendmentStore.contractAmendmentData, "experienceLevel", $$v)},expression:"\n            contractAmendmentStore.contractAmendmentData.experienceLevel\n          "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"maxlength":"12","icon":"mdi-currency-eur","label":_setup.t('Msg_.cityBonus') + '*',"value":_setup.helpers.convertValueToCurrency(
              _setup.contractAmendmentStore.contractAmendmentData.cityBonus
            ),"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.cityBonus')} ${_setup.t('Msg_.isRequired')}`,
              0
            ),
            _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
          ],"data-testid":"profile-contract-amendment-city-bonus-field"},on:{"change":function($event){_setup.contractAmendmentStore.contractAmendmentData.cityBonus =
              _setup.helpers.convertValueToCurrency($event)},"focus":function($event){_setup.contractAmendmentStore.contractAmendmentData.cityBonus =
              _setup.helpers.convertCurrencyToFloat($event)}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.hourlyWage')}*`,"value":_setup.helpers.convertValueToCurrency(
              _setup.contractAmendmentStore.contractAmendmentData.hourlyWage
            ),"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.hourlyWage')} ${_setup.t('Msg_.isRequired')}`,
              0
            ),
            _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
          ],"data-testid":"profile-contract-amendment-hourly-wage-field"},on:{"change":function($event){_setup.contractAmendmentStore.contractAmendmentData.hourlyWage =
              _setup.helpers.convertValueToCurrency($event)},"focus":function($event){_setup.contractAmendmentStore.contractAmendmentData.hourlyWage =
              _setup.helpers.convertCurrencyToFloat($event)}}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.nonDynamicAllowance')}*`,"value":_setup.helpers.convertValueToCurrency(
              _setup.contractAmendmentStore.contractAmendmentData.nonDynamicAllowance
            ),"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.nonDynamicAllowance')} ${_setup.t('Msg_.isRequired')}`,
              0
            ),
            _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
          ],"data-testid":"profile-contract-amendment-non-dynamic-allowance-field"},on:{"change":function($event){_setup.contractAmendmentStore.contractAmendmentData.nonDynamicAllowance =
              _setup.helpers.convertValueToCurrency($event)},"focus":function($event){_setup.contractAmendmentStore.contractAmendmentData.nonDynamicAllowance =
              _setup.helpers.convertCurrencyToFloat($event)}}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"profile-contract-amendment-care-allowance-field","maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.careAllowance')}*`,"value":_setup.helpers.convertValueToCurrency(
              _setup.contractAmendmentStore.contractAmendmentData.careAllowance
            ),"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.careAllowance')} ${_setup.t('Msg_.isRequired')}`,
              0
            ),
            _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
          ]},on:{"change":function($event){_setup.contractAmendmentStore.contractAmendmentData.careAllowance =
              _setup.helpers.convertValueToCurrency($event)},"focus":function($event){_setup.contractAmendmentStore.contractAmendmentData.careAllowance =
              _setup.helpers.convertCurrencyToFloat($event)}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.individualAllowance')}*`,"value":_setup.helpers.convertValueToCurrency(
              _setup.contractAmendmentStore.contractAmendmentData.individualAllowance
            ),"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.individualAllowance')} ${_setup.t('Msg_.isRequired')}`,
              0
            ),
            _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
          ],"data-testid":"profile-contract-amendment-individual-allowance-field"},on:{"change":function($event){_setup.contractAmendmentStore.contractAmendmentData.individualAllowance =
              _setup.helpers.convertValueToCurrency($event)},"focus":function($event){_setup.contractAmendmentStore.contractAmendmentData.individualAllowance =
              _setup.helpers.convertCurrencyToFloat($event)}}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"value":_setup.helpers.convertValueToCurrency(
              _setup.contractAmendmentStore.contractAmendmentData.monthlyBaseSalary
            ),"maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.monthlyBaseSalary')}*`,"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.monthlyBaseSalary')} ${_setup.t('Msg_.isRequired')}`,
              0
            ),
            _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
          ],"data-testid":"profile-contract-amendment-monthly-base-salary-field"},on:{"change":function($event){_setup.contractAmendmentStore.contractAmendmentData.monthlyBaseSalary =
              _setup.helpers.convertValueToCurrency($event)},"focus":function($event){_setup.contractAmendmentStore.contractAmendmentData.monthlyBaseSalary =
              _setup.helpers.convertCurrencyToFloat($event)}}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"profile-contract-amendment-thirteenth-salary-field","maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.13thSalary')}*`,"value":_setup.helpers.convertValueToCurrency(
              _setup.contractAmendmentStore.contractAmendmentData.thirteenthSalary
            ),"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.13thSalary')} ${_setup.t('Msg_.isRequired')}`,
              0
            ),
            _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
          ]},on:{"change":function($event){_setup.contractAmendmentStore.contractAmendmentData.thirteenthSalary =
              _setup.helpers.convertValueToCurrency($event)},"focus":function($event){_setup.contractAmendmentStore.contractAmendmentData.thirteenthSalary =
              _setup.helpers.convertCurrencyToFloat($event)}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.annualSalary')}*`,"value":_setup.helpers.convertValueToCurrency(
              _setup.contractAmendmentStore.contractAmendmentData.annualSalary
            ),"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.annualSalary')} ${_setup.t('Msg_.isRequired')}`,
              0
            ),
            _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
          ],"data-testid":"profile-contract-amendment-annual-salary-field"},on:{"change":function($event){_setup.contractAmendmentStore.contractAmendmentData.annualSalary =
              _setup.helpers.convertValueToCurrency($event)},"focus":function($event){_setup.contractAmendmentStore.contractAmendmentData.annualSalary =
              _setup.helpers.convertCurrencyToFloat($event)}}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.bonusTarget')}*`,"value":_setup.helpers.convertValueToCurrency(
              _setup.contractAmendmentStore.contractAmendmentData.bonusTarget
            ),"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.annualSalary')} ${_setup.t('Msg_.isRequired')}`,
              0
            ),
            _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
          ],"data-testid":"profile-contract-amendment-bonus-target-field"},on:{"change":function($event){_setup.contractAmendmentStore.contractAmendmentData.bonusTarget =
              _setup.helpers.convertValueToCurrency($event)},"focus":function($event){_setup.contractAmendmentStore.contractAmendmentData.bonusTarget =
              _setup.helpers.convertCurrencyToFloat($event)}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DatePicker,{attrs:{"label":_setup.t('Msg_.startDateChangeEffective') + '*',"allowed-dates":_setup.allowedStartDate,"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.startDateChangeEffective')} ${_setup.t('Msg_.isRequired')}`
            ),
          ],"data-testid":"profile-contract-amendment-start-date-field"},model:{value:(_setup.contractAmendmentStore.contractAmendmentData.startDate),callback:function ($$v) {_vm.$set(_setup.contractAmendmentStore.contractAmendmentData, "startDate", $$v)},expression:"contractAmendmentStore.contractAmendmentData.startDate"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RadioControl,{staticClass:"mt-1",attrs:{"label":_setup.t('Msg_.duration') + '*',"value":_setup.contractAmendmentStore.contractAmendmentData.duration,"label-true":_setup.t('Msg_.unlimited'),"label-false":_setup.t('Msg_.limited'),"return-value-true":1,"return-value-false":2,"data-testid":"profile-contract-amendment-duration-field"},on:{"change":function($event){return _setup.handleDurationField($event)}}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DatePicker,{attrs:{"label":_setup.endDateLabel,"allowed-dates":_setup.allowedEndDate,"disabled":_setup.isContractUnlimited,"rules":_setup.endDateRules,"data-testid":"profile-contract-amendment-end-date-field"},model:{value:(_setup.contractAmendmentStore.contractAmendmentData.endDate),callback:function ($$v) {_vm.$set(_setup.contractAmendmentStore.contractAmendmentData, "endDate", $$v)},expression:"contractAmendmentStore.contractAmendmentData.endDate"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DatePicker,{attrs:{"label":_setup.t('Msg_.signingDate') + '*',"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.signingDate')} ${_setup.t('Msg_.isRequired')}`
            ),
          ],"data-testid":"profile-contract-amendment-signing-date-field"},model:{value:(_setup.contractAmendmentStore.contractAmendmentData.signingDate),callback:function ($$v) {_vm.$set(_setup.contractAmendmentStore.contractAmendmentData, "signingDate", $$v)},expression:"contractAmendmentStore.contractAmendmentData.signingDate"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"disabled":"","label":_setup.t('Msg_.assigner'),"value":_setup.userStore.userInfo.name,"data-testid":"profile-contract-amendment-assigner-field"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
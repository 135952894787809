<template>
  <v-card class="elevation-0">
    <v-card-text>
      <p class="section-title pb-4">{{ t('Msg_.contractData') }}</p>
      <ContractData />
      <FullTimePartTime />
      <v-container class="pa-0">
        <v-row class="mt-6">
          <v-col cols="12">
            <v-btn
              class="cancel-variant-btn"
              :disabled="mainStore.isPostFormInProgress"
              @click="emit('redirect-user')"
              data-testid="profile-contract-amendment-cancel-button"
            >
              {{ t('Action_.buttons.cancel') }}
            </v-btn>
            <v-btn
              class="main-action-btn ml-3"
              @click="postContractAmendment()"
              :disabled="mainStore.isPostFormInProgress || !isFormValid"
              :loading="mainStore.isPostFormInProgress"
              data-testid="profile-contract-create-contract-amendment-button"
            >
              {{ t('Action_.buttons.createContractAmendment') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script setup>
import languages from '../locales/languages';
import ContractData from './contract-data.vue';
import FullTimePartTime from './full-time-part-time.vue';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useUserStore } from '@/store/pinia/user.store.js';
import { useContractAmendmentStore } from '@/store/pinia/contract-amendment.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { defineEmits, computed } from 'vue';
import { useContractTools } from '@/helpers/composables/contract-tools.js';

const mainStore = useMainStore();
const userStore = useUserStore();
const contractAmendmentStore = useContractAmendmentStore();
const emit = defineEmits(['submit-contract-amendment', 'redirect-user']);
const { t } = useI18n({ locale: 'en', messages: languages });
const contractTools = useContractTools();

// --- Local variables ---
const currencyKeys = [
  'cityBonus',
  'hourlyWage',
  'nonDynamicAllowance',
  'careAllowance',
  'individualAllowance',
  'monthlyBaseSalary',
  'thirteenthSalary',
  'annualSalary',
  'bonusTarget',
];

// --- Methods ---
const postContractAmendment = () => {
  contractAmendmentStore.contractAmendmentData.assigner =
    userStore.userInfo.sub;
  emit('submit-contract-amendment', prepareDataForPost());
};

const prepareDataForPost = () => {
  let payload = { ...{}, ...contractAmendmentStore.contractAmendmentData };

  contractTools.convertCurrencyFieldsToPost(currencyKeys, payload);

  payload.totalWeeklyWorkingHours = parseFloat(payload.totalWeeklyWorkingHours);
  payload.weeklyWorkingHours = contractTools.formatWeeklyWorkingHoursToPost(
    payload.weeklyWorkingHours
  );

  payload.additionalTraining =
    contractTools.replaceAdditionalTrainingKeysToPost(
      payload.additionalTraining
    );

  if (!payload.endDate) payload.endDate = null;

  return payload;
};

// --- Computed ---
const isFormValid = computed(() => {
  return Object.values(
    contractAmendmentStore.contractAmendmentFormValidation
  ).every((v) => v === true);
});
</script>

<style scoped lang="scss"></style>

<template>
  <v-container>
    <v-row>
      <v-col>
        <p class="card-title pt-1 pb-4">
          {{ t('Msg_.employee') }} -
          {{ profileStore.profileMenuInformation?.fullName }} -
          {{ t('Title_.subTitle.contractAmendment') }}
        </p>
      </v-col>
    </v-row>
    <v-container class="fill-height pa-0">
      <v-row>
        <v-col>
          <v-card class="elevation-0 pa-3">
            <ContractAmendmentForm
              @submit-contract-amendment="submitContractAmendment"
              @redirect-user="redirectUser()"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script setup>
import languages from './locales/languages';
import ContractAmendmentForm from './contractAmendment/contract-amendment-form.vue';
import helpers from '@/helpers/helpers';
import { useProfileStore } from '@/store/pinia/profile.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { useContractAmendmentStore } from '@/store/pinia/contract-amendment.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { computed, ref, onBeforeUnmount, defineProps } from 'vue';
import { useRouter, onBeforeRouteLeave } from 'vue-router/composables';
import { useContractTools } from '@/helpers/composables/contract-tools.js';

const router = useRouter();
const profileStore = useProfileStore();
const popupDialogStore = usePopupDialogStore();
const contractAmendmentStore = useContractAmendmentStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });
const contractTools = useContractTools();

// --- Local variables ---
const nextRoute = ref(null);
const initiatedForm = ref(null);
const ignoreFormChanges = ref(false);

// --- Props ---
const props = defineProps({
  currentProfileId: {
    type: String,
    default: null,
    required: false,
  },
});

// --- Methods ---
const fetchEmployeeInformation = async () => {
  try {
    await profileStore.getProfileMenuInformation(props.currentProfileId);
  } catch (error) {
    console.log(error);
  }
};

const getContractAmendmentData = async (id) => {
  try {
    await contractAmendmentStore.getContractAmendmentData(id);
    updateFullTimePartTime();
    handleAdditionalTraining();
    handleCompensationType();
    initializeDates();
    initializeJobSubtitle();
    initiatedForm.value = { ...{}, ...profileStore.contractAmendmentData };
  } catch (error) {
    console.log(error);
  }
};

const updateFullTimePartTime = () => {
  contractAmendmentStore.contractAmendmentData.weeklyWorkingHours =
    contractTools.createWorkingHoursArray(
      contractAmendmentStore.contractAmendmentData.weeklyWorkingHours
    );

  contractAmendmentStore.contractAmendmentData.weeklyWorkingHours.forEach(
    (day) => {
      day.hours = day.hours.toFixed(2);
    }
  );
};

const handleAdditionalTraining = () => {
  contractAmendmentStore.contractAmendmentData.additionalTraining =
    contractTools.replaceAdditionalTrainingKeys(
      contractAmendmentStore.contractAmendmentData.additionalTraining
    );
};

const handleCompensationType = () => {
  let compensationType;
  if (contractAmendmentStore.contractAmendmentData.contractType === 1) {
    if (
      contractAmendmentStore.contractAmendmentData.totalWeeklyWorkingHours >=
      38.5
    ) {
      compensationType = 1;
    } else {
      compensationType = 2;
    }
  } else {
    compensationType = 4;
  }
  contractAmendmentStore.contractAmendmentData.compensationType =
    compensationType;
};

const initializeDates = () => {
  if (!contractAmendmentStore.contractAmendmentData.endDate) {
    contractAmendmentStore.contractAmendmentData.endDate = '';
  }
  contractAmendmentStore.contractAmendmentData.signingDate = new Date(
    Date.now() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .substring(0, 10);
};

const initializeJobSubtitle = () => {
  if (!contractAmendmentStore.contractAmendmentData.jobSubtitle) {
    contractAmendmentStore.contractAmendmentData.jobSubtitle = '';
  }
};

const postContractAmendment = async (payload) => {
  const data = {
    id: props.currentProfileId,
    payload: payload,
  };

  try {
    await contractAmendmentStore.postContractAmendmentData(data);
  } catch (error) {
    console.log(error);
  } finally {
    redirectUser();
  }
};

const submitContractAmendment = async (payload) => {
  ignoreFormChanges.value = true;
  await profileStore.getSignatureStatus(props.currentProfileId);

  if (hasContractAmendment.value) {
    displayDialog('replace');
  } else {
    postContractAmendment(payload);
  }
};

const replaceAmendment = async () => {
  try {
    await contractAmendmentStore.deleteContractAmendment(
      profileStore.signatureInformation.id
    );
    postContractAmendment();
  } catch (error) {
    console.log(error);
  }
};

const redirectUser = () => {
  router.push({
    name: 'EmployeeProfileContractInformation',
    params: { employeeId: props.currentProfileId },
  });
};

const discardChanges = () => {
  ignoreFormChanges.value = true;
  router.push(nextRoute.value);
};

const displayDialog = (type) => {
  let params;

  if (type === 'replace') {
    params = {
      type: 'confirmation',
      title: tm('Action_.dialog.replaceContractAmendment.title'),
      message: tm('Action_.dialog.replaceContractAmendment.body'),
      actionBtnText: tm('Action_.buttons.proceed'),
      actionBtn: replaceAmendment,
    };
  } else {
    params = {
      type: 'confirmation',
      title: tm('Action_.dialog.saveChanges.title'),
      message: tm('Action_.dialog.saveChanges.body'),
      actionBtnText: tm('Action_.buttons.proceed'),
      actionBtn: discardChanges,
    };
  }

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

// --- Computed ---
const isFormAltered = computed(() => {
  let dataAltered;
  if (initiatedForm.value) {
    dataAltered = !helpers.compareTwoObjects(
      contractAmendmentStore.contractAmendmentData.value,
      initiatedForm.value
    );
  }
  return dataAltered;
});

const hasContractAmendment = computed(() => {
  return !!profileStore.signatureInformation.id;
});

// --- Lifecycle hooks ---
fetchEmployeeInformation();
getContractAmendmentData(props.currentProfileId);

onBeforeUnmount(() => {
  contractAmendmentStore.$partialReset('contractAmendmentData');
});

onBeforeRouteLeave((to, _, next) => {
  nextRoute.value = null;
  if (!isFormAltered.value || ignoreFormChanges.value) {
    nextRoute.value = to;
  }
  if (nextRoute.value) {
    next();
  } else {
    nextRoute.value = to;
    displayDialog();
  }
});
</script>

<style scoped lang="scss">
.stepper-center-text {
  text-align: -webkit-center;
  font-size: 0.65rem;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0"},[_c('v-form',{model:{value:(
      _setup.contractAmendmentStore.contractAmendmentFormValidation.contractData
    ),callback:function ($$v) {_vm.$set(_setup.contractAmendmentStore.contractAmendmentFormValidation, "contractData", $$v)},expression:"\n      contractAmendmentStore.contractAmendmentFormValidation.contractData\n    "}},[(
        !_setup.contractAmendmentStore.contractAmendmentData.isAddressCompleted ||
        !_setup.contractAmendmentStore.contractAmendmentData.isEmailFilled
      )?_c('v-row',[_c('v-col',{staticClass:"px-12",attrs:{"cols":"12"}},[(
            !_setup.contractAmendmentStore.contractAmendmentData.isAddressCompleted
          )?_c(_setup.WarningAlert,{attrs:{"text":_setup.t('Action_.alert.addressWarning')}}):_vm._e(),(!_setup.contractAmendmentStore.contractAmendmentData.isEmailFilled)?_c(_setup.WarningAlert,{staticClass:"mt-1",attrs:{"text":_setup.t('Action_.alert.emailWarning')}}):_vm._e()],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"clearable":false,"items":_setup.profileStore.profileHubs,"item-value":"id","item-text":"name","label":_setup.t('Msg_.mainHub') + '*',"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.mainHub')} ${_setup.t('Msg_.isRequired')}`
            ),
          ],"data-testid":"profile-contract-amendment-main-hub-dropdown"},model:{value:(_setup.contractAmendmentStore.contractAmendmentData.mainHub),callback:function ($$v) {_vm.$set(_setup.contractAmendmentStore.contractAmendmentData, "mainHub", $$v)},expression:"contractAmendmentStore.contractAmendmentData.mainHub"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.hub'),"value":_setup.billingHub,"disabled":"","data-testid":"profile-contract-amendment-billing-hub-field"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.company'),"value":_setup.companyName,"disabled":"","data-testid":"profile-contract-amendment-company-field"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"label":_setup.t('Msg_.contractType') + '*',"items":_setup.selectContractTypes,"data-testid":"profile-contract-amendment-contract-type-dropdown","clearable":false,"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.contractType')} ${_setup.t('Msg_.isRequired')}`
            ),
          ]},on:{"change":function($event){return _setup.handleCompensationType()}},model:{value:(_setup.contractAmendmentStore.contractAmendmentData.contractType),callback:function ($$v) {_vm.$set(_setup.contractAmendmentStore.contractAmendmentData, "contractType", $$v)},expression:"contractAmendmentStore.contractAmendmentData.contractType"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"label":_setup.t('Msg_.compensationType'),"items":_setup.selectCompensationTypes,"disabled":"","data-testid":"profile-contract-amendment-compensation-type-dropdown"},model:{value:(
            _setup.contractAmendmentStore.contractAmendmentData.compensationType
          ),callback:function ($$v) {_vm.$set(_setup.contractAmendmentStore.contractAmendmentData, "compensationType", $$v)},expression:"\n            contractAmendmentStore.contractAmendmentData.compensationType\n          "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"clearable":false,"label":_setup.t('Msg_.jobTitle') + '*',"items":_setup.selectQualificationType,"value":_setup.contractAmendmentStore.contractAmendmentData.jobTitle,"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.jobTitle')} ${_setup.t('Msg_.isRequired')}`
            ),
          ],"data-testid":"profile-contract-amendment-job-title-dropdown"},on:{"change":function($event){return _setup.handleJobTitle($event)}}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"profile-contract-amendment-job-subtitle-field","label":_setup.t('Msg_.jobSubtitle'),"rules":[
            _setup.rules.valueSize(
              50,
              `${_setup.t('Msg_.jobSubtitle')} ${_setup.t(
                'Msg_.rules.fieldMustHaveLessThan'
              )} 50 ${_setup.t('Msg_.rules.characters')}`
            ),
          ]},model:{value:(_setup.contractAmendmentStore.contractAmendmentData.jobSubtitle),callback:function ($$v) {_vm.$set(_setup.contractAmendmentStore.contractAmendmentData, "jobSubtitle", $$v)},expression:"contractAmendmentStore.contractAmendmentData.jobSubtitle"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.Combobox,{attrs:{"label":_setup.t('Msg_.additionalTraining') + '*',"items":_setup.selectAdditionalTrainingType,"value":_setup.contractAmendmentStore.contractAmendmentData.additionalTraining,"clearable":false,"rules":[
            _setup.rules.fieldRequired(
              `${_setup.t('Msg_.additionalTraining')} ${_setup.t('Msg_.isRequired')}`
            ),
            (v) =>
              v?.length > 0 ||
              `${_setup.t('Msg_.additionalTraining')} ${_setup.t('Msg_.isRequired')}`,
          ],"data-testid":"contract-creation-additional-training-field"},on:{"click":function($event){return _setup.handleAdditionaTrainingField($event)}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }